export const MenuList = [
  //Dashboard
  {
    title: "Dashboard",
    classsChange: "mm-collapse",
    iconStyle: <i className="flaticon-381-networking"></i>,
    to: "/dashboard",
  },

  //User Management
  {
    title: "User Management",
    classsChange: "mm-collapse",
    iconStyle: <i class="fa fa-users"></i>,
    to: "/user-management",
  },
  // Category Management
  {
    title: "Category Management",
    classsChange: "mm-collapse",
    iconStyle: <i class="flaticon-381-app"></i>,
    to: "/category-management",
  },
  {
    title: "Fee Management",
    classsChange: "mm-collapse",
    iconStyle: <i class="flaticon-381-newspaper"></i>,
    to: "/fee-management",
  },
  {
    title: "Service config",
    classsChange: "mm-collapse",
    iconStyle: <i class="flaticon-381-settings-7"></i>,
    to: "/service-management",
  },
  {
    title: "Review Management",
    classsChange: "mm-collapse",
    iconStyle: <i class="flaticon-381-star-1"></i>,
    to: "/review-management",
  },
  {
    title: "Notifications",
    classsChange: "mm-collapse",
    iconStyle: <i class="flaticon-381-ring"></i>,
    to: "/notification-management",
  },

  //Pages
  //   {
  //     title: "Pages",
  //     classsChange: "mm-collapse",
  //     iconStyle: <i className="flaticon-381-layer-1"></i>,
  //     content: [
  //       {
  //         title: "Error",
  //         hasMenu: true,
  //         content: [
  //           {
  //             title: "Error 400",
  //             to: "page-error-400",
  //           },
  //           {
  //             title: "Error 403",
  //             to: "page-error-403",
  //           },
  //           {
  //             title: "Error 404",
  //             to: "page-error-404",
  //           },
  //           {
  //             title: "Error 500",
  //             to: "page-error-500",
  //           },
  //           {
  //             title: "Error 503",
  //             to: "page-error-503",
  //           },
  //         ],
  //       },
  //       {
  //         title: "Lock Screen",
  //         to: "page-lock-screen",
  //       },
  //     ],
  //   },
];
